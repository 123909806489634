@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f3f4f6;
}

/* Roboto Thin */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

/* Roboto Thin Italic */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

/* Roboto Light */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/* Roboto Light Italic */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/* Roboto Regular */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Roboto Italic */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Roboto Medium */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* Roboto Medium Italic */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

/* Roboto Bold */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Roboto Bold Italic */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

/* Roboto Black */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

/* Roboto Black Italic */
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}